import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';
import BlogComponent from 'components/BlogPost/BlogPost';

const BlogPost = ({data}) => (
	<Layout>
		<SEO title="Blog Post" />
		<BlogComponent data={data.contentfulBlogPost} />
	</Layout>
);

export default BlogPost

export const query = graphql`
	query($slug: String!) {
		contentfulBlogPost(slug: { eq: $slug }) {
			title
			subtitle
			date
			image {
				fluid {
					...GatsbyContentfulFluid_tracedSVG
				}
			}
			author {
				name
				position
			}
			text {
					json
			}
		}
	}
`
